(function($) {
  $(document).ready(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 1000) {
        $('#scrollup').addClass('is-visible');
      } else {
        $('#scrollup').removeClass('is-visible');
      }
    });

    $('#scrollup').click(function () {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });
  });
})(jQuery);
