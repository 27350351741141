(function($) {
  $(document).ready(function() {
    $('#menu-menu_header a').on('click', function(event) {
      var page = $(this).attr('href');
      var speed = 750;

      if ($('body').hasClass('home')) {
        var parts = page.split('/');
        var anchor = parts[1];

        if (anchor.indexOf('#') > -1) {
          $('.navbar-toggle').trigger('click');
          $('html, body').animate( { scrollTop: $(anchor).offset().top }, speed );
          event.preventDefault();
        }
      }
    });
  });
})(jQuery);
